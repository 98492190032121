import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const AboutPageTemplate = ({ title, content, contentComponent, featuredimage }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section-post">

      <section className="page-header">
        <div className="container">
          <div className="content subheaderlinks">
              <h1 className="breadcrumbs-links">
                About {title}
              </h1>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container content">
          <div className="columns">
              <div className="column">
                {featuredimage ? (
                  <div className="column featured-thumbnail is-10">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: featuredimage,
                        alt: `featured image for ${title}`,
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <div className="column featured-thumbnail-artist">
                <PageContent className="content" content={content} />
              </div>
          </div>
        </div>
      </section>

    </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        featuredimage={post.frontmatter.featuredimage}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 520, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
